// Here you can add other styles
.c-sidebar-brand img{
    width: 100% !important;
    height: 100% !important;
}
.c-sidebar-nav-link:hover{
    color: var(--cui-sidebar-nav-link-hover-color,hsla(0,0%,100%,.87));
    text-decoration: none;
    background: hsla(0,0%,100%,.05);
    background: var(--cui-sidebar-nav-link-hover-bg,hsla(0,0%,100%,.05)) !important;
}
 img{
     width: 100% !important;
     height: 100%;
 }
 .c-default-layout .card-group{
  -webkit-box-shadow: 3px 3px 5px 6px #000;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow:    3px 3px 5px 6px #000;  /* Firefox 3.5 - 3.6 */
  box-shadow:         3px 3px 5px 6px rgba(83, 83, 83, 0.342);  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
   
 }
 .c-sidebar .c-sidebar-brand {
width: 67% !important;
}
.navBr{
background-color: #fff !important;
}
.c-sidebar .c-sidebar-brand {
  padding-left: 10px;
  background: none !important;
}
 .c-default-layout .card-group:hover{


 }
 .c-default-layout{
     background-repeat: no-repeat;
     background-position: center;
     background-size: 110% 110%;
    // background-size: cover;
     animation: shrink 5s infinite alternate;

 }
 .logo .card-body{
  display: flex !important;
  align-items: center;
 }

 @keyframes shrink {
    0% {
      background-size: 130% 130%;
    }
    100% {
      background-size: 100% 100%;
    }
  }
  .c-active{

  }
  // ***************************************************dashboard*********************************************
  .DashboardUi .card{
    border-radius: 5px;
    box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
    transition: 5ms;
    margin: 20px !important;
    
  }
  .DashboardUi .card:hover{
    border-radius: 5px;
    box-shadow: none;
  }
  .DashboardUi .card .card-body {
    align-items: center;
    text-align: center;
    transition: 5ms;
    padding: 0;
    display: block;overflow: hidden;position: relative;
  }
  .DashboardUi .card .card-body .icon{
    //color: #1F9F54;
    height: 50px;
    width: 50px;
    line-height: 100px;
    border-radius: 225px;
    transition: 5ms;
  }
  .DashboardUi .card .card-body img{
    box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
    background-color: #d7e9de61;
    border: 1px solid #d7e9de61;
    width:100% !important;

transition: 0.6s;
  }
  .DashboardUi .card .card-body:hover img{
    box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
    background-color: #fff;
    border: 1px solid #00560c8a;
    transform: scale(1.2);
  }
  .DashboardUi .card .card-body:hover .icon{
    color: #1F9F54;
    height: 50px;
    width: 50px;
    line-height: 100px;
    border-radius: 225px;
    
  }
  .DashboardUi .btn{
    //background-color: #1F9F54;
  }
  .DashboardUi .card .card-body .icon:not(.icon-c-s):not(.icon-custom-size).icon-lg .c-icon:not(.c-icon-c-s):not(.c-icon-custom-size){

  }
  .DashboardUi .c-icon:not(.c-icon-c-s):not(.c-icon-custom-size){
   
    width: 5rem !important;
    height: 5rem !important;
    font-size: 1.25rem;
    }
    .secondR{
        margin-top: 100px !important;
    }
  .DashboardUi .card   .service-name{
    background-color: #1F9F54;
    bottom: -46px;
    position: absolute;
    width: 100%;
    text-align: center;
    z-index: 1;
    height: 50px;
  }

  .DashboardUi .card   .service-name svg{
    height: 4rem !important;
    position: absolute;
    width: 4rem !important;
    line-height: 75px;
    z-index: -1;
    padding: 14px;
    bottom: 29px;
    background-color: #1F9F54;
    color: #fff;
    border-radius: 50%;
    top: -18px;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-45%) translateY(-50%);
    transition: 0.6s;

  }

  .DashboardUi .card:hover  svg{
    -webkit-transform: translateX(-50%) translateY(-60%); 
  -moz-transform: translateX(0%) translateY(-60%);
  -ms-transform: translateX(0%) translateY(-60%);
  -o-transform: translateX(0%) translateY(-60%);
  transform: translateX(-45%) translateY(-60%);
    }
  .DashboardUi .card  h5{
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  margin-top: 12px;
  }
  .DashboardUi .card .card-body .title .num{
    color: #636f83;
    font-weight: 900;
  
  }
  .card-border-c-blue{
    border-top: 4px solid #1F9F54;
  }
  .c-sidebar-nav-dropdown-items li a:hover{

  }
  .c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover{
    color: #1F9F54;
  }
  .c-sidebar .c-sidebar-nav-link:hover .c-sidebar-nav-icon, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon :hover {
    color: #1F9F54;
}
.c-sidebar-nav-link:hover{
    color: #1F9F54;
}
.c-sidebar .c-sidebar-nav-link:hover .c-sidebar-nav-icon, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
    color: #1F9F54;
}
  .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link.c-active, .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle.c-active {
    color: #1F9F54;
}